import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import ServiceDetailsContent from "../components/ServiceDetails/ServiceDetailsContent"
import SoftwareDevelopmentImage from "../assets/images/services/SoftwareDevelopment.png"

const Details = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Software Development"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Software Development"
      />
      <ServiceDetailsContent
        aboutService="Making software programs 
          are not an easy job, but our 
          Software developers are very
           experienced and familiar
            with all modern tools"
        technologies={["JavaScript", "HTML", "Git", "CSS", "SQL"]}
        detailImage={SoftwareDevelopmentImage}
      />
      {/* <RelatedServices /> */}
      <Footer />
    </Layout>
  )
}

export default Details
